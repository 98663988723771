import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, cn } from "../lib/helpers";

import { responsiveTitle2, small } from "../components/typography.module.css";

export const query = graphql`
  query PressPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      mainImage {
        asset {
          url
        }
      }
    }
    press: allSanityPress {
      edges {
        node {
          source
          publishedAt(formatString: "MMMM YYYY")
          title
          id
          url
        }
      }
    }
  }
`;

const PressPage = (props) => {
  const { data, errors, location } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;
  const press = data && data.press && mapEdgesToNodes(data.press);
  const pressNodes = press;
  const seoImage = site.mainImage ? `${site.mainImage.asset.url}?w=1200&h=600` : "";

  // console.log(pressNodes);/
  return (
    <Layout location={location}>
      <SEO title="Press" image={seoImage} />
      <Container>
        <ul className="max-w-lg mx-auto">
          {pressNodes?.map((press) => (
            <React.Fragment key={press.id}>
              {press.url && (
                <li className="text-right mb-8.5">
                  <a href={press.url} title={press.title} target="blank">
                  <h2 className={cn(responsiveTitle2, "text-center")}>{press.title}</h2>
                  <div className={cn(small, "w-full text-center mt-1")}>
                    {press.source}, {press.publishedAt}
                  </div>
                  </a>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </Container>
    </Layout>
  );
};

export default PressPage;
